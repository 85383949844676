import axios from 'axios'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'PreviewPdf',
  components: {
    VuePdfEmbed
  },
  data: () => ({
    dialog: false,
    loading: false,
    pdf: null,
    videoUrl: null,
    name: '',
    timeout: null
  }),
  computed: {
  },
  beforeMount () {
    this.downloadResource(this.$route.params.resourceId)
  },
  created () {
    window.addEventListener('resize', this.windowsResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowsResize)
    clearTimeout(this.timeout)
  },
  methods: {
    windowsResize () {
      this.loading = true
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loading = false
      }, 250)
    },

    async downloadResource (uuid) {
      this.loading = true
      let response;
      if (this.$route.query.type === 'video') {
        this.videoUrl = `/api/v1/library/resource/${uuid}/download`
      } else {
        response = await axios.get(`/api/v1/library/resource/${uuid}/download`, { responseType: 'blob' })
        if (response.status === 200) {
          this.pdf = await this.blob2Base64(new Blob([response.data]))
        }
      }
      response = await axios.get(`/api/v1/library/resource/${uuid}`)
      if (response.status === 200) {
        this.name = response.data.name
      }
      this.loading = false
    },
    blob2Base64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => resolve(reader.result.toString())
        reader.onerror = error => reject(error)
      })
    }
  }
}
